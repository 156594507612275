import React, {Component} from 'react';
import axios from "axios";
import ShowSlide from "../../layout/ShowSlide";
import Loading from "../../../assets/images/loading.gif";
import moment from "moment";

class ListagemVideos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            loading: false
        }
    }

    getDados = async () => {
        this.setState({loading: true})
        await axios.get(`${process.env.REACT_APP_API}/videos?filter[orderby]=date&order=desc`)
            .then(response => {
                console.log(response.data)
                this.setState({
                    dados: response.data,
                    loading: false
                })
            })
    }

    componentDidMount() {
        this.getDados()
    }

    render() {
        const {dados, loading} = this.state;
        return (
            <>
                <ShowSlide/>
                <div className="content">
                    {loading ? (
                        <div style={{textAlign: 'center'}}>
                            <img src={Loading} alt="Carregando"/>
                        </div>
                    ) : (
                        <div className="noticia">
                            <h1>Vídeos </h1>
                            {
                                dados.map((item, index) => (
                                    <div className="videos__list">
                                        <h5>Em { moment(item.date).format('D [de] MMMM, YYYY') }</h5>
                                        <h2 dangerouslySetInnerHTML={{__html: item.title.rendered}}></h2>
                                        <div key={index} className="videoWrapper"
                                             dangerouslySetInnerHTML={{__html: item.acf.video}}></div>
                                        <br />
                                    </div>
                                ))
                            }
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default ListagemVideos;