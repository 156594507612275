import React from 'react';

const Erro404 = () => {
    return (
        <div className="erro404">
            <h1>Erro 404</h1>
            <h2>Página não localizada!</h2>
        </div>
    );
};

export default Erro404;