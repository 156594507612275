import React, {Component} from 'react';
import Loading from "../../../assets/images/loading.gif";
import axios from "axios";
import Usuario from '../../../assets/images/user.png';
import ShowSlide from "../../layout/ShowSlide";

class Associados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            loading: false,
            currentPage: 1,
            perPage: 10
        }
    }

    handleClick = (event) => {
        this.setState({
            currentPage: Number(event.target.id)
        });
        window.scrollTo(0, 750)
    }

    getDados = async () => {
        this.setState({loading: true})
        await axios.get(`${process.env.REACT_APP_API}/associados?filter[orderby]=title&order=asc&per_page=100`)
            .then(response => {
                this.setState({
                    dados: response.data,
                    loading: false
                })
            })
    }

    componentDidMount() {
        this.getDados();
    }

    render() {
        const {dados, loading, currentPage, perPage} = this.state;

        const lastData = currentPage * perPage;
        const firstData = lastData - perPage;
        const currentData = dados.slice(firstData, lastData);

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(dados.length / perPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <li
                    key={number}
                    id={number}
                    onClick={this.handleClick}
                >
                    {number}
                </li>
            );
        });

        return (
            <>
                <ShowSlide/>
                <div className="content">
                    {loading ? (
                        <div style={{textAlign: 'center'}}>
                            <img src={Loading} alt="Carregando"/>
                        </div>
                    ) : (
                        <div className="associados">
                            <h1>Associados</h1>
                            {
                                currentData.map(item => (
                                    <div key={item.id} className="list-associados">
                                        { item.acf.foto.url ? (
                                            <img src={item.acf.foto.url} alt={item.title.rendered} width='80'/>
                                        ) : (
                                            <img src={Usuario} alt={item.title.rendered} width='80'/>
                                        )}
                                        <div>
                                            <h2 dangerouslySetInnerHTML={{__html: item.title.rendered}}></h2>
                                            <h4>Currículo: <a href={item.acf.curriculo} target="_blank" rel="noopener noreferrer">{ item.acf.curriculo}</a></h4>
                                            <h4>Email: <a href={`mailto:${item.acf.email}`} target="_blank" rel="noopener noreferrer">{ item.acf.email}</a></h4>
                                            <h4 dangerouslySetInnerHTML={{__html: item.acf.telefone}}></h4>
                                            <h4>Cadastro ativo desde { item.acf.cadastro_ativo }</h4>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    )}
                    <ul className="pagination">
                        {renderPageNumbers}
                    </ul>
                </div>
            </>
        );
    }
}

export default Associados;
