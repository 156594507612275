import React, {Component} from 'react'

export default class MenuMob extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
        };
    }
    toggleClass = () => {
        const currentState = this.state.active;
        this.setState({ active: !currentState });
    };

    render() {
        return (
            <div>
                <nav role="navigation">
                    <div id="menuToggle" onClick={() => this.toggleClass()}>
                        <input type="checkbox" />
                        <span></span>
                        <span></span>
                        <span></span>
                        <ul id="menu" className={this.state.active ? 'list' : ''}>
                            <li><a href="/">Início</a></li>
                            <li>Quem Somos
                                <ul>
                                    <li className="sub__menu"><a href="/quem-somos/historico">Histórico</a></li>
                                    <li className="sub__menu"><a href="/quem-somos/diretoria">Diretoria</a></li>
                                    <li className="sub__menu"><a href="/quem-somos/documentos">Estatuto e documentos</a></li>
                                </ul>
                            </li>
                            <li>Associação
                                <ul>
                                    <li className="sub__menu"><a href="/associacao/associados">Associados</a></li>
                                    <li className="sub__menu"><a href="/associacao/associe">Associe-se </a></li>
                                </ul>
                            </li>
                            <li>FIPED
                                <ul>
                                    <li className="sub__menu"><a href="/fiped/historico">Histórico do evento</a></li>
                                    <li className="sub__menu"><a href="/fiped/informes">Informes FIPED</a></li>
                                    <li className="sub__menu"><a href="/fiped/edicoes">Todas as edições </a></li>
                                </ul>
                            </li>
                            <li>Repositório Digital
                                <ul>
                                    <li className="sub__menu"><a href="/publicacoes/anais">Anais</a></li>
                                    <li className="sub__menu"><a href="/publicacoes/ebooks">E-books  </a></li>
                                    <li className="sub__menu"><a href="/publicacoes/entrevistas">Entrevistas  </a></li>
                                </ul>
                            </li>
                            <li><a href="/observatorio">Observatório</a></li>
                            <li><a href="/videos">Vídeos</a></li>
                            <li><a href="/noticias">Notícias</a></li>
                            <li><a href="/contatos">Contatos</a></li>
                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
}
