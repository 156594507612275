import React from 'react';
import PropTypes from 'prop-types';
import { SRLWrapper } from "simple-react-lightbox";

const GaleriaImagens = props => {
    let {imagens} = props;
    return (
        <div className="bloco-conteudo">
            <h1 className="topicos">Galeria de Imagens </h1>
            <div className="linha"></div>
            <div className="conteudo-imagens">
                <SRLWrapper>
                    {
                        imagens.map((item, index) => (
                            <div key={index}>
                                <img src={item.acf.imagem.url} alt={item.title.rendered}/>
                            </div>
                            )
                        )
                    }
                </SRLWrapper>
            </div>
        </div>
    );
};

GaleriaImagens.propTypes = {
    imagens: PropTypes.array
};

export default GaleriaImagens;