import React, {Component} from 'react';
import axios from 'axios';
import UltimasNoticias from "./UltimasNoticias";
import FacaParte from "./FacaParte";
import GaleriaImagens from "./GaleraImagens";
import Mapa from "./Mapa";
import ShowSlide from "../../layout/ShowSlide";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noticias: [],
            galeria: []
        }
    }

    getNoticias = async () => {
        let {data} = await axios.get(`${process.env.REACT_APP_API}/posts?per_page=3`);
        this.setState({
            noticias: data
        })
    }

    getImagens = async () => {
        let {data} = await axios.get(`${process.env.REACT_APP_API}/galeria/?per_page=8`);
        this.setState({
            galeria: data
        })
    }

    componentDidMount() {
        this.getNoticias();
        this.getImagens();
    }

    render() {
        let {noticias, galeria} = this.state
        return (
            <div>
                <ShowSlide/>
                <UltimasNoticias noticias={noticias} />
                <FacaParte />
                <GaleriaImagens imagens={galeria}/>
                <Mapa/>
            </div>
        );
    }
};

export default Home;