import React from "react";

const Form = () => {

  return (
    <>
      <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
        <input type="hidden" name="cmd" value="_s-xclick"/>
        <input type="hidden" name="hosted_button_id" value="TQPK6G2APFDBS"/>
        <table>
          <tr>
            <td>
              <div className="input-group form-control form-control-lg">
                <select name="os0">
                  <option value="Professores e pesquisadores do Ensino Superior">Professores e pesquisadores do Ensino
                    Superior : R$100,00 BRL - anualmente
                  </option>
                  <option value="Professores da Educação Básica / Pós-Graduando">Professores da Educação Básica /
                    Pós-Graduando : R$60,00 BRL - anualmente
                  </option>
                  <option value="Profissionais da Educação">Profissionais da Educação : R$50,00 BRL - anualmente
                  </option>
                  <option value="Estudantes de Pós-Graduação">Estudantes de Pós-Graduação : R$40,00 BRL - anualmente
                  </option>
                  <option value="Estudantes de Graduação e outras categorias">Estudantes de Graduação e outras
                    categorias
                    : R$20,00 BRL - anualmente
                  </option>
                  <option value="Sócios Institucionais">Sócios Institucionais : R$150,00 BRL - anualmente</option>
                </select>
              </div>
            </td>
          </tr>
        </table>
        <input type="hidden" name="currency_code" value="BRL"/>
        <input type="image" src="https://www.paypalobjects.com/pt_BR/BR/i/btn/btn_subscribeCC_LG.gif" border="0"
               name="submit" alt="PayPal - A maneira fácil e segura de enviar pagamentos online!"/>
        <img alt="" border="0" src="https://www.paypalobjects.com/pt_BR/i/scr/pixel.gif" width="1" height="1"/>
      </form>
    </>
  );
}

export default Form;
