import React, {Component} from 'react';
import Form from "./Form";
import ShowSlide from "../../layout/ShowSlide";

class Paypal extends Component {

  render() {
    return (
      <>
        <ShowSlide/>
        <div className="content">
          <div className="noticia">
            <h1>Dados registrados</h1>
            <h4>Seus dados foram computados com sucesso!
              <br />Para pagar a anuidade pelo PayPal (selecione a opção abaixo):
            </h4>
          <Form/>
          </div>
        </div>
      </>
    );
  }
}

export default Paypal;
