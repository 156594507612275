import React, { Component } from 'react';
import Logo from './../../assets/images/logo-final.png'
import axios from "axios";

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noticias: []
        }
    }

    getNoticias = async () => {
        let {data} = await axios.get(`${process.env.REACT_APP_API}/posts?per_page=3`);
        this.setState({
            noticias: data
        })
    }

    componentDidMount() {
        this.getNoticias();
    }

    render() {
        const { noticias } = this.state;
        return (
            <>
                <div id="footer">
                    <div className="logo">
                        <a href="/"><img src={Logo} alt="Logo AINPGP"/></a>
                    </div>
                    <div className="redes-sociais">

                    </div>
                    <div className="noticias-final">
                        <h2>Últimas Notícias</h2>
                        {
                            noticias.map((item, index) => (
                                    <a href={`/noticias/${item.slug}`} key={index}>
                                        <h3 dangerouslySetInnerHTML={{__html: item.title.rendered}} />
                                    </a>
                                )
                            )
                        }
                    </div>
                    <div className="contatos">
                        <h2>Nossos Contatos</h2>
                        <h4>SEDE DA ATUAL DIRETORIA</h4>
                        <h5 style={{ marginBottom: '10px'}}>Campus Cajazeiras, da Universidade Federal de Campina Grande (UFCG), Rua Sérgio Moreira de Figueiredo, S/n - Populares, Cajazeiras - PB, 58900-000</h5>
                        <h3><a href="mailto:contato@ainpgp.com">contato@ainpgp.com</a></h3>
                    </div>
                </div>
                <div className="copyright">
                    &copy; Associação Internacional de Pesquisa na Graduação em Pedagogia
                    <span className="fsenaweb">
                        <a href="https://fsenaweb.me" target="_blank" rel="noopener noreferrer"
                              title="Especializado em desenvolver soluções web para o sucesso dos seus negócios">

                        fsenaweb</a>
                    </span>
                </div>
            </>
        );
    }
};