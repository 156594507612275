import React, { useState } from 'react';
import axios from 'axios';
import ShowSlide from "../../layout/ShowSlide";
import Loading from "../../../assets/images/loading.gif";

import { useForm } from "react-hook-form";


const Associe = (props) => {

  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [typeSocio, setTypeSocio] = useState('cpf')

  const dados = (data) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('tipo_socio', data.tipo_socio)
    formData.append('atividade', data.atividade)
    formData.append('cpf', data.cpf)
    formData.append('cnpj', data.cnpj)
    formData.append('formacao_doutorado', data.formacao_doutorado)
    formData.append('formacao_especializacao', data.formacao_especializacao)
    formData.append('formacao_fundamento', data.formacao_fundamento)
    formData.append('formacao_graduacao', data.formacao_graduacao)
    formData.append('formacao_medio', data.formacao_medio)
    formData.append('formacao_mestrado', data.formacao_mestrado)
    formData.append('formacao_outra', data.formacao_outra)
    formData.append('formacao_pos_doctor', data.formacao_pos_doctor)
    formData.append('local_cidade', data.local_cidade)
    formData.append('local_email', data.local_email)
    formData.append('local_endereco', data.local_endereco)
    formData.append('local_pais', data.local_pais)
    formData.append('local_telefone', data.local_telefone)
    formData.append('local_uf', data.local_uf)
    formData.append('nascimento', data.nascimento)
    formData.append('nome', data.nome)
    formData.append('razao', data.razao)
    formData.append('pagamento', data.pagamento)
    formData.append('res_bairro', data.res_bairro)
    formData.append('res_celular', data.res_celular)
    formData.append('res_cep', data.res_cep)
    formData.append('res_cidade', data.res_cidade)
    formData.append('res_email', data.res_email)
    formData.append('res_endereco', data.res_endereco)
    formData.append('res_fixo', data.res_fixo)
    formData.append('res_lattes', data.res_lattes)
    formData.append('res_numero', data.res_numero)
    formData.append('res_pais', data.res_pais)
    formData.append('res_uf', data.res_uf)
    formData.append('foto', data.foto)

    axios.post(`${process.env.REACT_APP_INSCRICAO}`, formData,
      {
        headers: {'Content-Type': 'multipart/form-data'}
      })
      .then(response => {
        console.log('Solicitação enviada com sucesso!')
        if(data.pagamento === 'Paypal') {
          props.history.push("/associacao/paypal");
        } else {
          props.history.push('/associacao/callback')
        }
        setLoading(false)
      })
      .catch((e) => {
        console.log('Erro ao enviar solicitação, tente novamente.')
        console.log(e)
        setLoading(false)
      })
  };

  const setSocio = (e) => {
    setTypeSocio(e.target.value);
  }

  return (
    <>
      <ShowSlide/>
      <div className="content">
        {loading ? (
          <div style={{textAlign: 'center'}}>
            <img src={Loading} alt="Carregando"/>
          </div>
        ) : (
          <div className="noticia">
            <h1>Associe-se</h1>
            <h4>
              <p>
                <strong>Prezado(a) futuro Sócio(a) da AINPGP, </strong>
                por favor, preencha corretamente os dados cadastrais e realize o pagamento ao final do formulário:
              </p>
            </h4>
            <div className="formulario">
              <form onSubmit={handleSubmit(dados)}>
                <div className="input-group">
                  <label htmlFor="cpf" className="radio">
                    <input onChange={(e) => setSocio(e)}
                           type="radio"
                           id="cpf"
                           value="cpf"
                           name="tipo_socio"
                           ref={register}
                    />
                    Pessoa Física
                  </label>
                  <label htmlFor="cnpj" className="radio">
                    <input onChange={(e) => setSocio(e)}
                           type="radio"
                           id="cnpj"
                           value="cnpj"
                           ref={register}
                           name="tipo_socio"
                    />
                    Pessoa Jurídica
                  </label>
                </div>
                <hr/>
                { typeSocio === 'cpf' ? (
                  <div className="input-group-area">
                    <div className="input-group form-control form-control-xs">
                      <label htmlFor="cpf">CPF</label>
                      <input type="text"
                             ref={register({ minLength: 14})}
                             name="cpf"
                             placeholder="000.000.000-00"

                      />
                      {errors.cpf && (
                        <span className="error">CPF incorreto</span>
                      ) }

                    </div>
                    <div className="input-group form-control form-control-lg">
                      <label htmlFor="nome">Nome completo</label>
                      <input type="text"
                             name="nome"
                             ref={register({ required: true, minLength: 10  })}
                             placeholder="Nome completo"
                      />
                      {errors.nome && (
                        <span className="error">O campo contém informações inválidas</span>
                      ) }
                    </div>
                    <div className="input-group form-control form-control-xs">
                      <label htmlFor="nascimento">Data de Nascimento</label>
                      <input type="text"
                             name="nascimento"
                             placeholder="01/01/2020"
                             ref={register({ required: true, pattern: /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/ })}
                      />
                      {errors.nascimento && (
                        <span className="error">Data incorreta</span>
                      ) }
                    </div>
                  </div>
                ) : (
                  <div className="input-group-area">
                    <div className="input-group form-control form-control-xs">
                      <label htmlFor="cnpj">CNPJ</label>
                      <input type="text"
                             name="cnpj"
                             placeholder="00.000.000/0000-00"
                             ref={register({ minLength: 18 })}
                      />
                      {errors.cnpj && (
                        <span className="error">Dado incorreto</span>
                      ) }
                    </div>
                    <div className="input-group form-control form-control-lg">
                      <label htmlFor="razao">Razão Social</label>
                      <input type="text"
                             name="razao"
                             placeholder="Razão Social"
                             ref={register({ required: true, minLength: 10  })}
                      />
                      {errors.razao && (
                        <span className="error">O campo contém informações inválidas</span>
                      ) }
                    </div>
                  </div>
                )}
                <hr/>
                <h3> <strong>Dados pessoais e profissionais</strong></h3>
                <br/>
                <div className="input-group-area">
                  <div className="input-group form-control form-control-lg">
                    <label htmlFor="res_endereco">Endereço Residencial</label>
                    <input type="text"
                           name="res_endereco"
                           placeholder="Endereço Residencial"
                           ref={register({ required: true })}
                    />
                    {errors.res_endereco && (
                      <span className="error">Campo requerido</span>
                    ) }
                  </div>
                  <div className="input-group form-control form-control-sd">
                    <label htmlFor="res_numero">Número</label>
                    <input type="text" ref={register} name="res_numero" placeholder="Número"  />
                  </div>
                  <div className="input-group form-control form-control-sd">
                    <label htmlFor="res_cep">CEP</label>
                    <input type="text" ref={register} name="res_cep" placeholder="CEP"  />
                  </div>
                </div>
                <div className="input-group-area">
                  <div className="input-group form-control form-control-xs">
                    <label htmlFor="res_bairro">Bairro</label>
                    <input type="text" ref={register} name="res_bairro" placeholder="Bairro"  />
                  </div>
                  <div className="input-group form-control form-control-xs">
                    <label htmlFor="res_cidade">Cidade</label>
                    <input type="text"
                           name="res_cidade"
                           placeholder="Cidade"
                           ref={register({ required: true })}
                    />
                    {errors.res_cidade && (
                      <span className="error">Campo requerido</span>
                    ) }
                  </div>
                  <div className="input-group form-control form-control-sd">
                    <label htmlFor="res_uf">UF</label>
                    <select name="res_uf" ref={register}>
                      <option value="">Selecione</option>
                      <option value="AC">AC</option>
                      <option value="AL">AL</option>
                      <option value="AM">AM</option>
                      <option value="AP">AP</option>
                      <option value="BA">BA</option>
                      <option value="CE">CE</option>
                      <option value="DF">DF</option>
                      <option value="ES">ES</option>
                      <option value="GO">GO</option>
                      <option value="MA">MA</option>
                      <option value="MG">MG</option>
                      <option value="MS">MS</option>
                      <option value="MT">MT</option>
                      <option value="PA">PA</option>
                      <option value="PB">PB</option>
                      <option value="PE">PE</option>
                      <option value="PI">PI</option>
                      <option value="PR">PR</option>
                      <option value="RJ">RJ</option>
                      <option value="RN" selected>RN</option>
                      <option value="RS">RS</option>
                      <option value="RO">RO</option>
                      <option value="RR">RR</option>
                      <option value="SC">SC</option>
                      <option value="SE">SE</option>
                      <option value="SP">SP</option>
                      <option value="TO">TO</option>
                    </select>
                  </div>
                  <div className="input-group form-control form-control-sd">
                    <label htmlFor="res_pais">Pais</label>
                    <select name="res_pais" ref={register}>
                      <option value="Brasil" selected>Brasil</option>
                      <option value="Afeganistão">Afeganistão</option>
                      <option value="África do Sul">África do Sul</option>
                      <option value="Albânia">Albânia</option>
                      <option value="Alemanha">Alemanha</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antilhas Holandesas">Antilhas Holandesas</option>
                      <option value="Antárctida">Antárctida</option>
                      <option value="Antígua e Barbuda">Antígua e Barbuda</option>
                      <option value="Argentina">Argentina</option>
                      <option value="Argélia">Argélia</option>
                      <option value="Armênia">Armênia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Arábia Saudita">Arábia Saudita</option>
                      <option value="Austrália">Austrália</option>
                      <option value="Áustria">Áustria</option>
                      <option value="Azerbaijão">Azerbaijão</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrein">Bahrein</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belize">Belize</option>
                      <option value="Benim">Benim</option>
                      <option value="Bermudas">Bermudas</option>
                      <option value="Bielorrússia">Bielorrússia</option>
                      <option value="Bolívia">Bolívia</option>
                      <option value="Botswana">Botswana</option>
                      <option value="Brunei">Brunei</option>
                      <option value="Bulgária">Bulgária</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Butão">Butão</option>
                      <option value="Bélgica">Bélgica</option>
                      <option value="Bósnia e Herzegovina">Bósnia e Herzegovina</option>
                      <option value="Cabo Verde">Cabo Verde</option>
                      <option value="Camarões">Camarões</option>
                      <option value="Camboja">Camboja</option>
                      <option value="Canadá">Canadá</option>
                      <option value="Catar">Catar</option>
                      <option value="Cazaquistão">Cazaquistão</option>
                      <option value="Chade">Chade</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Chipre">Chipre</option>
                      <option value="Colômbia">Colômbia</option>
                      <option value="Comores">Comores</option>
                      <option value="Coreia do Norte">Coreia do Norte</option>
                      <option value="Coreia do Sul">Coreia do Sul</option>
                      <option value="Costa do Marfim">Costa do Marfim</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Croácia">Croácia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Dinamarca">Dinamarca</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Egito">Egito</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Emirados Árabes Unidos">Emirados Árabes Unidos</option>
                      <option value="Equador">Equador</option>
                      <option value="Eritreia">Eritreia</option>
                      <option value="Escócia">Escócia</option>
                      <option value="Eslováquia">Eslováquia</option>
                      <option value="Eslovênia">Eslovênia</option>
                      <option value="Espanha">Espanha</option>
                      <option value="Estados Federados da Micronésia">Estados Federados da Micronésia</option>
                      <option value="Estados Unidos">Estados Unidos</option>
                      <option value="Estônia">Estônia</option>
                      <option value="Etiópia">Etiópia</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Filipinas">Filipinas</option>
                      <option value="Finlândia">Finlândia</option>
                      <option value="França">França</option>
                      <option value="Gabão">Gabão</option>
                      <option value="Gana">Gana</option>
                      <option value="Geórgia">Geórgia</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Granada">Granada</option>
                      <option value="Gronelândia">Gronelândia</option>
                      <option value="Grécia">Grécia</option>
                      <option value="Guadalupe">Guadalupe</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guernesei">Guernesei</option>
                      <option value="Guiana">Guiana</option>
                      <option value="Guiana Francesa">Guiana Francesa</option>
                      <option value="Guiné">Guiné</option>
                      <option value="Guiné Equatorial">Guiné Equatorial</option>
                      <option value="Guiné-Bissau">Guiné-Bissau</option>
                      <option value="Gâmbia">Gâmbia</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungria">Hungria</option>
                      <option value="Ilha Bouvet">Ilha Bouvet</option>
                      <option value="Ilha de Man">Ilha de Man</option>
                      <option value="Ilha do Natal">Ilha do Natal</option>
                      <option value="Ilha Heard e Ilhas McDonald">Ilha Heard e Ilhas McDonald</option>
                      <option value="Ilha Norfolk">Ilha Norfolk</option>
                      <option value="Ilhas Cayman">Ilhas Cayman</option>
                      <option value="Ilhas Cocos (Keeling)">Ilhas Cocos (Keeling)</option>
                      <option value="Ilhas Cook">Ilhas Cook</option>
                      <option value="Ilhas Feroé">Ilhas Feroé</option>
                      <option value="Ilhas Geórgia do Sul e Sandwich do Sul">Ilhas Geórgia do Sul e Sandwich do Sul
                      </option>
                      <option value="Ilhas Malvinas">Ilhas Malvinas</option>
                      <option value="Ilhas Marshall">Ilhas Marshall</option>
                      <option value="Ilhas Menores Distantes dos Estados Unidos">Ilhas Menores Distantes dos Estados
                        Unidos
                      </option>
                      <option value="Ilhas Salomão">Ilhas Salomão</option>
                      <option value="Ilhas Virgens Americanas">Ilhas Virgens Americanas</option>
                      <option value="Ilhas Virgens Britânicas">Ilhas Virgens Britânicas</option>
                      <option value="Ilhas Åland">Ilhas Åland</option>
                      <option value="Indonésia">Indonésia</option>
                      <option value="Inglaterra">Inglaterra</option>
                      <option value="Índia">Índia</option>
                      <option value="Iraque">Iraque</option>
                      <option value="Irlanda do Norte">Irlanda do Norte</option>
                      <option value="Irlanda">Irlanda</option>
                      <option value="Irã">Irã</option>
                      <option value="Islândia">Islândia</option>
                      <option value="Israel">Israel</option>
                      <option value="Itália">Itália</option>
                      <option value="Iêmen">Iêmen</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japão">Japão</option>
                      <option value="Jersey">Jersey</option>
                      <option value="Jordânia">Jordânia</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Laos">Laos</option>
                      <option value="Lesoto">Lesoto</option>
                      <option value="Letônia">Letônia</option>
                      <option value="Libéria">Libéria</option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lituânia">Lituânia</option>
                      <option value="Luxemburgo">Luxemburgo</option>
                      <option value="Líbano">Líbano</option>
                      <option value="Líbia">Líbia</option>
                      <option value="Macau">Macau</option>
                      <option value="Macedônia">Macedônia</option>
                      <option value="Madagáscar">Madagáscar</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Maldivas">Maldivas</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Malásia">Malásia</option>
                      <option value="Marianas Setentrionais">Marianas Setentrionais</option>
                      <option value="Marrocos">Marrocos</option>
                      <option value="Martinica">Martinica</option>
                      <option value="Mauritânia">Mauritânia</option>
                      <option value="Maurícia">Maurícia</option>
                      <option value="Mayotte">Mayotte</option>
                      <option value="Moldávia">Moldávia</option>
                      <option value="Mongólia">Mongólia</option>
                      <option value="Montenegro">Montenegro</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Moçambique">Moçambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="México">México</option>
                      <option value="Mônaco">Mônaco</option>
                      <option value="Namíbia">Namíbia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Nicarágua">Nicarágua</option>
                      <option value="Nigéria">Nigéria</option>
                      <option value="Niue">Niue</option>
                      <option value="Noruega">Noruega</option>
                      <option value="Nova Caledônia">Nova Caledônia</option>
                      <option value="Nova Zelândia">Nova Zelândia</option>
                      <option value="Níger">Níger</option>
                      <option value="Omã">Omã</option>
                      <option value="Palau">Palau</option>
                      <option value="Palestina">Palestina</option>
                      <option value="Panamá">Panamá</option>
                      <option value="Papua-Nova Guiné">Papua-Nova Guiné</option>
                      <option value="Paquistão">Paquistão</option>
                      <option value="Paraguai">Paraguai</option>
                      <option value="País de Gales">País de Gales</option>
                      <option value="Países Baixos">Países Baixos</option>
                      <option value="Peru">Peru</option>
                      <option value="Pitcairn">Pitcairn</option>
                      <option value="Polinésia Francesa">Polinésia Francesa</option>
                      <option value="Polônia">Polônia</option>
                      <option value="Porto Rico">Porto Rico</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Quirguistão">Quirguistão</option>
                      <option value="Quênia">Quênia</option>
                      <option value="Reino Unido">Reino Unido</option>
                      <option value="República Centro-Africana">República Centro-Africana</option>
                      <option value="República Checa">República Checa</option>
                      <option value="República Democrática do Congo">República Democrática do Congo</option>
                      <option value="República do Congo">República do Congo</option>
                      <option value="República Dominicana">República Dominicana</option>
                      <option value="Reunião">Reunião</option>
                      <option value="Romênia">Romênia</option>
                      <option value="Ruanda">Ruanda</option>
                      <option value="Rússia">Rússia</option>
                      <option value="Saara Ocidental">Saara Ocidental</option>
                      <option value="Saint Martin">Saint Martin</option>
                      <option value="Saint-Barthélemy">Saint-Barthélemy</option>
                      <option value="Saint-Pierre e Miquelon">Saint-Pierre e Miquelon</option>
                      <option value="Samoa Americana">Samoa Americana</option>
                      <option value="Samoa">Samoa</option>
                      <option value="Santa Helena, Ascensão e Tristão da Cunha">Santa Helena, Ascensão e Tristão da
                        Cunha
                      </option>
                      <option value="Santa Lúcia">Santa Lúcia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Serra Leoa">Serra Leoa</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Singapura">Singapura</option>
                      <option value="Somália">Somália</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Suazilândia">Suazilândia</option>
                      <option value="Sudão">Sudão</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Suécia">Suécia</option>
                      <option value="Suíça">Suíça</option>
                      <option value="Svalbard e Jan Mayen">Svalbard e Jan Mayen</option>
                      <option value="São Cristóvão e Nevis">São Cristóvão e Nevis</option>
                      <option value="São Marino">São Marino</option>
                      <option value="São Tomé e Príncipe">São Tomé e Príncipe</option>
                      <option value="São Vicente e Granadinas">São Vicente e Granadinas</option>
                      <option value="Sérvia">Sérvia</option>
                      <option value="Síria">Síria</option>
                      <option value="Tadjiquistão">Tadjiquistão</option>
                      <option value="Tailândia">Tailândia</option>
                      <option value="Taiwan">Taiwan</option>
                      <option value="Tanzânia">Tanzânia</option>
                      <option value="Terras Austrais e Antárticas Francesas">Terras Austrais e Antárticas Francesas
                      </option>
                      <option value="Território Britânico do Oceano Índico">Território Britânico do Oceano Índico
                      </option>
                      <option value="Timor-Leste">Timor-Leste</option>
                      <option value="Togo">Togo</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Toquelau">Toquelau</option>
                      <option value="Trinidad e Tobago">Trinidad e Tobago</option>
                      <option value="Tunísia">Tunísia</option>
                      <option value="Turcas e Caicos">Turcas e Caicos</option>
                      <option value="Turquemenistão">Turquemenistão</option>
                      <option value="Turquia">Turquia</option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Ucrânia">Ucrânia</option>
                      <option value="Uganda">Uganda</option>
                      <option value="Uruguai">Uruguai</option>
                      <option value="Uzbequistão">Uzbequistão</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Vaticano">Vaticano</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Vietname">Vietname</option>
                      <option value="Wallis e Futuna">Wallis e Futuna</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                      <option value="Zâmbia">Zâmbia</option>
                    </select>
                  </div>
                </div>
                <div className="input-group-area">
                  <div className="input-group form-control form-control-xs">
                    <label htmlFor="res_celular">Telefone Celular</label>
                    <input type="text"
                           name="res_celular"
                           placeholder="(00) 00000-0000"
                           ref={register({ required: true })}
                    />
                    {errors.res_celular && (
                      <span className="error">Campo requerido</span>
                    ) }
                  </div>
                  <div className="input-group form-control form-control-xs">
                    <label htmlFor="res_fixo">Telefone Fixo</label>
                    <input type="text" ref={register} name="res_fixo" placeholder="Telefone Fixo"  />
                  </div>
                  <div className="input-group form-control form-control-lg">
                    <label htmlFor="res_email">Email</label>
                    <input type="email"
                           name="res_email"
                           placeholder="Email"
                           ref={register({
                             required: 'Campo obrigatório',
                             pattern: {
                               value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                               message: 'E-mail inválido',
                             },
                           })}
                    />
                    {errors.res_email && (
                      <span className="error">{ errors.res_email.message }</span>
                    ) }
                  </div>
                </div>
                <hr/>
                <div className="input-group-area">
                  <div className="input-group form-control form-control-lg">
                    <label htmlFor="local_endereco">Local de Trabalho</label>
                    <input type="text" name="local_endereco"
                           placeholder="Endereço Residencial"
                           ref={register}/>
                  </div>
                </div>
                <div className="input-group-area">
                  <div className="input-group form-control form-control-md">
                    <label htmlFor="local_cidade">Cidade</label>
                    <input type="text" ref={register} name="local_cidade" placeholder="Cidade"  />
                  </div>
                  <div className="input-group form-control form-control-sd">
                    <label htmlFor="local_uf">UF</label>
                    <select name="local_uf" ref={register}>
                      <option value="">Selecione</option>
                      <option value="AC">AC</option>
                      <option value="AL">AL</option>
                      <option value="AM">AM</option>
                      <option value="AP">AP</option>
                      <option value="BA">BA</option>
                      <option value="CE">CE</option>
                      <option value="DF">DF</option>
                      <option value="ES">ES</option>
                      <option value="GO">GO</option>
                      <option value="MA">MA</option>
                      <option value="MG">MG</option>
                      <option value="MS">MS</option>
                      <option value="MT">MT</option>
                      <option value="PA">PA</option>
                      <option value="PB">PB</option>
                      <option value="PE">PE</option>
                      <option value="PI">PI</option>
                      <option value="PR">PR</option>
                      <option value="RJ">RJ</option>
                      <option value="RN" selected>RN</option>
                      <option value="RS">RS</option>
                      <option value="RO">RO</option>
                      <option value="RR">RR</option>
                      <option value="SC">SC</option>
                      <option value="SE">SE</option>
                      <option value="SP">SP</option>
                      <option value="TO">TO</option>
                    </select>
                  </div>
                  <div className="input-group form-control form-control-sd">
                    <label htmlFor="local_pais">Pais</label>
                    <select name="local_pais" ref={register}>
                      <option value="Brasil" selected>Brasil</option>
                      <option value="Afeganistão">Afeganistão</option>
                      <option value="África do Sul">África do Sul</option>
                      <option value="Albânia">Albânia</option>
                      <option value="Alemanha">Alemanha</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antilhas Holandesas">Antilhas Holandesas</option>
                      <option value="Antárctida">Antárctida</option>
                      <option value="Antígua e Barbuda">Antígua e Barbuda</option>
                      <option value="Argentina">Argentina</option>
                      <option value="Argélia">Argélia</option>
                      <option value="Armênia">Armênia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Arábia Saudita">Arábia Saudita</option>
                      <option value="Austrália">Austrália</option>
                      <option value="Áustria">Áustria</option>
                      <option value="Azerbaijão">Azerbaijão</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrein">Bahrein</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belize">Belize</option>
                      <option value="Benim">Benim</option>
                      <option value="Bermudas">Bermudas</option>
                      <option value="Bielorrússia">Bielorrússia</option>
                      <option value="Bolívia">Bolívia</option>
                      <option value="Botswana">Botswana</option>
                      <option value="Brunei">Brunei</option>
                      <option value="Bulgária">Bulgária</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Butão">Butão</option>
                      <option value="Bélgica">Bélgica</option>
                      <option value="Bósnia e Herzegovina">Bósnia e Herzegovina</option>
                      <option value="Cabo Verde">Cabo Verde</option>
                      <option value="Camarões">Camarões</option>
                      <option value="Camboja">Camboja</option>
                      <option value="Canadá">Canadá</option>
                      <option value="Catar">Catar</option>
                      <option value="Cazaquistão">Cazaquistão</option>
                      <option value="Chade">Chade</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Chipre">Chipre</option>
                      <option value="Colômbia">Colômbia</option>
                      <option value="Comores">Comores</option>
                      <option value="Coreia do Norte">Coreia do Norte</option>
                      <option value="Coreia do Sul">Coreia do Sul</option>
                      <option value="Costa do Marfim">Costa do Marfim</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Croácia">Croácia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Dinamarca">Dinamarca</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Egito">Egito</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Emirados Árabes Unidos">Emirados Árabes Unidos</option>
                      <option value="Equador">Equador</option>
                      <option value="Eritreia">Eritreia</option>
                      <option value="Escócia">Escócia</option>
                      <option value="Eslováquia">Eslováquia</option>
                      <option value="Eslovênia">Eslovênia</option>
                      <option value="Espanha">Espanha</option>
                      <option value="Estados Federados da Micronésia">Estados Federados da Micronésia</option>
                      <option value="Estados Unidos">Estados Unidos</option>
                      <option value="Estônia">Estônia</option>
                      <option value="Etiópia">Etiópia</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Filipinas">Filipinas</option>
                      <option value="Finlândia">Finlândia</option>
                      <option value="França">França</option>
                      <option value="Gabão">Gabão</option>
                      <option value="Gana">Gana</option>
                      <option value="Geórgia">Geórgia</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Granada">Granada</option>
                      <option value="Gronelândia">Gronelândia</option>
                      <option value="Grécia">Grécia</option>
                      <option value="Guadalupe">Guadalupe</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guernesei">Guernesei</option>
                      <option value="Guiana">Guiana</option>
                      <option value="Guiana Francesa">Guiana Francesa</option>
                      <option value="Guiné">Guiné</option>
                      <option value="Guiné Equatorial">Guiné Equatorial</option>
                      <option value="Guiné-Bissau">Guiné-Bissau</option>
                      <option value="Gâmbia">Gâmbia</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungria">Hungria</option>
                      <option value="Ilha Bouvet">Ilha Bouvet</option>
                      <option value="Ilha de Man">Ilha de Man</option>
                      <option value="Ilha do Natal">Ilha do Natal</option>
                      <option value="Ilha Heard e Ilhas McDonald">Ilha Heard e Ilhas McDonald</option>
                      <option value="Ilha Norfolk">Ilha Norfolk</option>
                      <option value="Ilhas Cayman">Ilhas Cayman</option>
                      <option value="Ilhas Cocos (Keeling)">Ilhas Cocos (Keeling)</option>
                      <option value="Ilhas Cook">Ilhas Cook</option>
                      <option value="Ilhas Feroé">Ilhas Feroé</option>
                      <option value="Ilhas Geórgia do Sul e Sandwich do Sul">Ilhas Geórgia do Sul e Sandwich do Sul
                      </option>
                      <option value="Ilhas Malvinas">Ilhas Malvinas</option>
                      <option value="Ilhas Marshall">Ilhas Marshall</option>
                      <option value="Ilhas Menores Distantes dos Estados Unidos">Ilhas Menores Distantes dos Estados
                        Unidos
                      </option>
                      <option value="Ilhas Salomão">Ilhas Salomão</option>
                      <option value="Ilhas Virgens Americanas">Ilhas Virgens Americanas</option>
                      <option value="Ilhas Virgens Britânicas">Ilhas Virgens Britânicas</option>
                      <option value="Ilhas Åland">Ilhas Åland</option>
                      <option value="Indonésia">Indonésia</option>
                      <option value="Inglaterra">Inglaterra</option>
                      <option value="Índia">Índia</option>
                      <option value="Iraque">Iraque</option>
                      <option value="Irlanda do Norte">Irlanda do Norte</option>
                      <option value="Irlanda">Irlanda</option>
                      <option value="Irã">Irã</option>
                      <option value="Islândia">Islândia</option>
                      <option value="Israel">Israel</option>
                      <option value="Itália">Itália</option>
                      <option value="Iêmen">Iêmen</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japão">Japão</option>
                      <option value="Jersey">Jersey</option>
                      <option value="Jordânia">Jordânia</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Laos">Laos</option>
                      <option value="Lesoto">Lesoto</option>
                      <option value="Letônia">Letônia</option>
                      <option value="Libéria">Libéria</option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lituânia">Lituânia</option>
                      <option value="Luxemburgo">Luxemburgo</option>
                      <option value="Líbano">Líbano</option>
                      <option value="Líbia">Líbia</option>
                      <option value="Macau">Macau</option>
                      <option value="Macedônia">Macedônia</option>
                      <option value="Madagáscar">Madagáscar</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Maldivas">Maldivas</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Malásia">Malásia</option>
                      <option value="Marianas Setentrionais">Marianas Setentrionais</option>
                      <option value="Marrocos">Marrocos</option>
                      <option value="Martinica">Martinica</option>
                      <option value="Mauritânia">Mauritânia</option>
                      <option value="Maurícia">Maurícia</option>
                      <option value="Mayotte">Mayotte</option>
                      <option value="Moldávia">Moldávia</option>
                      <option value="Mongólia">Mongólia</option>
                      <option value="Montenegro">Montenegro</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Moçambique">Moçambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="México">México</option>
                      <option value="Mônaco">Mônaco</option>
                      <option value="Namíbia">Namíbia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Nicarágua">Nicarágua</option>
                      <option value="Nigéria">Nigéria</option>
                      <option value="Niue">Niue</option>
                      <option value="Noruega">Noruega</option>
                      <option value="Nova Caledônia">Nova Caledônia</option>
                      <option value="Nova Zelândia">Nova Zelândia</option>
                      <option value="Níger">Níger</option>
                      <option value="Omã">Omã</option>
                      <option value="Palau">Palau</option>
                      <option value="Palestina">Palestina</option>
                      <option value="Panamá">Panamá</option>
                      <option value="Papua-Nova Guiné">Papua-Nova Guiné</option>
                      <option value="Paquistão">Paquistão</option>
                      <option value="Paraguai">Paraguai</option>
                      <option value="País de Gales">País de Gales</option>
                      <option value="Países Baixos">Países Baixos</option>
                      <option value="Peru">Peru</option>
                      <option value="Pitcairn">Pitcairn</option>
                      <option value="Polinésia Francesa">Polinésia Francesa</option>
                      <option value="Polônia">Polônia</option>
                      <option value="Porto Rico">Porto Rico</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Quirguistão">Quirguistão</option>
                      <option value="Quênia">Quênia</option>
                      <option value="Reino Unido">Reino Unido</option>
                      <option value="República Centro-Africana">República Centro-Africana</option>
                      <option value="República Checa">República Checa</option>
                      <option value="República Democrática do Congo">República Democrática do Congo</option>
                      <option value="República do Congo">República do Congo</option>
                      <option value="República Dominicana">República Dominicana</option>
                      <option value="Reunião">Reunião</option>
                      <option value="Romênia">Romênia</option>
                      <option value="Ruanda">Ruanda</option>
                      <option value="Rússia">Rússia</option>
                      <option value="Saara Ocidental">Saara Ocidental</option>
                      <option value="Saint Martin">Saint Martin</option>
                      <option value="Saint-Barthélemy">Saint-Barthélemy</option>
                      <option value="Saint-Pierre e Miquelon">Saint-Pierre e Miquelon</option>
                      <option value="Samoa Americana">Samoa Americana</option>
                      <option value="Samoa">Samoa</option>
                      <option value="Santa Helena, Ascensão e Tristão da Cunha">Santa Helena, Ascensão e Tristão da
                        Cunha
                      </option>
                      <option value="Santa Lúcia">Santa Lúcia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Serra Leoa">Serra Leoa</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Singapura">Singapura</option>
                      <option value="Somália">Somália</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Suazilândia">Suazilândia</option>
                      <option value="Sudão">Sudão</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Suécia">Suécia</option>
                      <option value="Suíça">Suíça</option>
                      <option value="Svalbard e Jan Mayen">Svalbard e Jan Mayen</option>
                      <option value="São Cristóvão e Nevis">São Cristóvão e Nevis</option>
                      <option value="São Marino">São Marino</option>
                      <option value="São Tomé e Príncipe">São Tomé e Príncipe</option>
                      <option value="São Vicente e Granadinas">São Vicente e Granadinas</option>
                      <option value="Sérvia">Sérvia</option>
                      <option value="Síria">Síria</option>
                      <option value="Tadjiquistão">Tadjiquistão</option>
                      <option value="Tailândia">Tailândia</option>
                      <option value="Taiwan">Taiwan</option>
                      <option value="Tanzânia">Tanzânia</option>
                      <option value="Terras Austrais e Antárticas Francesas">Terras Austrais e Antárticas Francesas
                      </option>
                      <option value="Território Britânico do Oceano Índico">Território Britânico do Oceano Índico
                      </option>
                      <option value="Timor-Leste">Timor-Leste</option>
                      <option value="Togo">Togo</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Toquelau">Toquelau</option>
                      <option value="Trinidad e Tobago">Trinidad e Tobago</option>
                      <option value="Tunísia">Tunísia</option>
                      <option value="Turcas e Caicos">Turcas e Caicos</option>
                      <option value="Turquemenistão">Turquemenistão</option>
                      <option value="Turquia">Turquia</option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Ucrânia">Ucrânia</option>
                      <option value="Uganda">Uganda</option>
                      <option value="Uruguai">Uruguai</option>
                      <option value="Uzbequistão">Uzbequistão</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Vaticano">Vaticano</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Vietname">Vietname</option>
                      <option value="Wallis e Futuna">Wallis e Futuna</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                      <option value="Zâmbia">Zâmbia</option>
                    </select>
                  </div>
                </div>
                <div className="input-group-area">
                  <div className="input-group form-control form-control-xs">
                    <label htmlFor="local_telefone">Telefone Trabalho</label>
                    <input type="text" ref={register} name="local_telefone" placeholder="Telefone Trabalho"  />
                  </div>
                  <div className="input-group form-control form-control-lg">
                    <label htmlFor="local_email">Email Trabalho</label>
                    <input type="email" name="local_email" placeholder="Email"
                           ref={register({
                             pattern: {
                               value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                               message: 'E-mail inválido',
                             },
                           })}
                    />
                    {errors.local_email && (
                      <span className="error">{ errors.local_email.message }</span>
                    ) }
                  </div>
                </div>
                <div className="input-group-area">
                  <div className="input-group form-control form-control-lg">
                    <label htmlFor="local_lattes">LINK para o LATTES</label>
                    <input type="text"
                           name="res_lattes"
                           placeholder="LINK para o LATTES"
                           ref={register({ required: 'Campo obrigatório'})}
                    />
                    {errors.res_lattes && (
                      <span className="error">{ errors.res_lattes.message }</span>
                    ) }
                  </div>
                </div>
                <hr/>
                <h3> <strong>Formação inicial e continuada </strong> (informar área e IES)</h3>
                <br/>
                <div className="input-group-area">
                  <div className="input-group form-control form-control-lg">
                    <label htmlFor="formacao_fundamento">Ensino Fundamental</label>
                    <input type="text" ref={register} name="formacao_fundamento" placeholder="Ensino Fundamental"  />
                  </div>
                  <div className="input-group form-control form-control-lg">
                    <label htmlFor="formacao_medio">Ensino Médio</label>
                    <input type="text" ref={register} name="formacao_medio" placeholder="Ensino Médio"  />
                  </div>
                </div>
                <div className="input-group-area">
                  <div className="input-group form-control form-control-lg">
                    <label htmlFor="formacao_graduacao">Graduação</label>
                    <input type="text" ref={register} name="formacao_graduacao" placeholder="Graduação"  />
                  </div>
                  <div className="input-group form-control form-control-lg">
                    <label htmlFor="formacao_especializacao">Especialização</label>
                    <input type="text" ref={register} name="formacao_especializacao" placeholder="Especialização"  />
                  </div>
                </div>
                <div className="input-group-area">
                  <div className="input-group form-control form-control-lg">
                    <label htmlFor="formacao_mestrado">Mestrado</label>
                    <input type="text" ref={register} name="formacao_mestrado" placeholder="Mestrado"  />
                  </div>
                  <div className="input-group form-control form-control-lg">
                    <label htmlFor="formacao_doutorado">Doutorado</label>
                    <input type="text" ref={register} name="formacao_doutorado" placeholder="Doutorado"  />
                  </div>
                </div>
                <div className="input-group-area">
                  <div className="input-group form-control form-control-lg">
                    <label htmlFor="formacao_pos_doctor">Pós-Doutorado</label>
                    <input type="text" ref={register} name="formacao_pos_doctor" placeholder="Pós-Doutorado"  />
                  </div>
                  <div className="input-group form-control form-control-lg">
                    <label htmlFor="formacao_outra">Outra</label>
                    <input type="text" ref={register} name="formacao_outra" placeholder="Outra"  />
                  </div>
                </div>
                <hr/>
                <h3> <strong>Atividade Profissional Principal para enquadramento da Anuidade</strong></h3>
                <br/>
                <div className="input-group">
                  <label htmlFor="superior" className="radio">
                    <input type="radio" ref={register} id="superior" value="Professores, pesquisadores de nível superior" name="atividade"/>
                    Professores, pesquisadores de nível superior
                  </label>
                  <label htmlFor="basica" className="radio">
                    <input type="radio" ref={register} id="basica" value="Professores da Educação Básica" name="atividade"/>
                    Professores da Educação Básica
                  </label>
                  <label htmlFor="profissional" className="radio">
                    <input type="radio" ref={register} id="profissional" value="Profissionais da educação" name="atividade"/>
                    Profissionais da educação
                  </label>
                  <label htmlFor="estudante" className="radio">
                    <input type="radio" ref={register} id="estudante" value="Estudantes de Pós-Graduação" name="atividade"/>
                    Estudantes de Pós-Graduação
                  </label>
                  <label htmlFor="graducao" className="radio">
                    <input type="radio" ref={register} id="graducao" value="Estudantes de Graduação e outras categorias" name="atividade"/>
                    Estudantes de Graduação e outras categorias
                  </label>
                </div>
                <hr/>
                <hr/>
                <h3> <strong>Forma de pagamento</strong></h3>
                <br/>
                <div className="input-group">
                  <label htmlFor="Paypal" className="radio">
                    <input type="radio" ref={register} id="Paypal" value="Paypal" name="pagamento"/>
                    Paypal
                  </label>
                  <label htmlFor="Transferencia" className="radio">
                    <input type="radio" ref={register} id="Transferencia" value="Transferencia" name="pagamento"/>
                    Transferência <br/>
                  </label>
                  <div style={{ marginLeft: '25px'}}>
                  <small >Banco do Brasil <br />Agência 1109-6 <br /> CC 45190-8</small>
                  </div>
                </div>
                <hr/>
                <div className="input-group-area">
                  <button type="submit" className="btn btn-primary">Concordo</button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Associe;
