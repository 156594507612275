import React, {Component} from 'react';
import axios from 'axios';
import 'moment/locale/pt-br';
import moment from "moment";
import Loading from './../../../assets/images/loading.gif';

class Noticia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noticia: {
                titulo: null,
                conteudo: null,
                data: null
            },
            loading: false,
        }
    }

    getNoticia = async () => {
        this.setState({ loading: true })
        const { match: { params } } = this.props;
        await axios.get(`${process.env.REACT_APP_API}/observatorio?slug=${params.slug}`)
            .then(response => {
                this.setState({
                    noticia: {
                        titulo: response.data[0].title.rendered,
                        conteudo: response.data[0].content.rendered,
                        data: response.data[0].date
                    },
                    loading: false
                })
            })
    }

    componentDidMount() {
        this.getNoticia();
;    }

    render() {
        const { noticia, loading } = this.state;
        return (
            <div className="content">
                { loading ? (
                    <div style={{ textAlign: 'center' }}>
                        <img src={Loading} alt="Carregando"/>
                    </div>
                ) : (
                    <div className="noticia">
                        <h1 dangerouslySetInnerHTML={{__html: noticia.titulo }} className="internal"></h1>
                        <h6>{ moment(noticia.data).format('D [de] MMMM, YYYY') }</h6>
                        <h4 dangerouslySetInnerHTML={{__html: noticia.conteudo }}></h4>
                    </div>
                )}
            </div>
        );
    }
}

export default Noticia;
