import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

const UltimasNoticias = props => {
    moment.locale('pt-br');
    return (
        <div className="bloco-conteudo">
            <h1 className="topicos">Últimas notícias </h1>
            <div className="linha"></div>
            <div className="conteudo">
                {
                    props.noticias.map((item, index) => (
                            <div className="noticia-unique" key={index}>
                                <Link to={`/noticias/${item.slug}`}>
                                    <img src={item.acf.imagem_destaque.url} alt={item.title.rendered}/>
                                </Link>
                                <Link to={`/noticias/${item.slug}`}>
                                    <h3 dangerouslySetInnerHTML={{__html: item.title.rendered}} />
                                </Link>
                                <h4>{ moment(item.date).format('D [de] MMMM, YYYY') }</h4>
                                <h5>{ item.acf.topico.label} </h5>
                            </div>
                        )
                    )
                }
            </div>
        </div>
    );
};

UltimasNoticias.propTypes = {
    noticias: PropTypes.array
};

export default UltimasNoticias;