import React, {Component} from 'react';
import axios from "axios";
import Loading from "../../../assets/images/loading.gif";
import Documento from "../../../assets/images/icon-documentos.png";
import ShowSlide from "../../layout/ShowSlide";

class Documentos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documentos: [],
            loading: false
        }
    }

    getDados = async () => {
        this.setState({loading: true})
        await axios.get(`${process.env.REACT_APP_API}/documentos`)
            .then(response => {
                this.setState({
                    documentos: response.data,
                    loading: false
                })
            })
    }

    componentDidMount() {
        this.getDados()
    }

    render() {
        const {documentos, loading} = this.state;
        return (
            <>
                <ShowSlide/>
                <div className="content">
                    {loading ? (
                        <div style={{textAlign: 'center'}}>
                            <img src={Loading} alt="Carregando"/>
                        </div>
                    ) : (
                        <div className="noticia">
                            <h1>Estatuto e Documentos</h1>
                            {
                                documentos.map(item => (
                                    <div key={item.id} className="documentos">
                                        <a href={item.acf.arquivo.url} download target="_blank" rel="noopener noreferrer"><img src={Documento} alt={item.content.rendered} width='80'/></a>
                                        <div>
                                            <h2 dangerouslySetInnerHTML={{__html: item.title.rendered }}></h2>
                                            <h5 dangerouslySetInnerHTML={{__html: item.content.rendered }}></h5>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default Documentos;