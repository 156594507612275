import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import SimpleReactLightbox from "simple-react-lightbox";
import App from './App';
import MenuTopo from "./components/layout/MenuTopo";
import Footer from "./components/layout/Footer";

ReactDOM.render(
    <div className="geral">
        <MenuTopo/>
            <SimpleReactLightbox>
                <App />
            </SimpleReactLightbox>
        <Footer />
    </div>,
    document.getElementById('root'));

serviceWorker.unregister();
