import React, {Component} from 'react';
import {Slide} from 'react-slideshow-image';
import axios from "axios";

const properties = {
    duration: 5000,
    transitionDuration: 300,
    infinite: true,
    indicators: false,
    arrows: true,
}


export default class ShowSlide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            banner: []
        }
    }

    getBanner = async () => {
        let {data} = await axios.get(`${process.env.REACT_APP_API}/banner`);
        this.setState({
            banner: data
        })
    }

    componentDidMount() {
        this.getBanner()
    }

    render() {
        const {banner} = this.state
        return (
            <div className="slide-container">

                <Slide {...properties}>
                    {
                        banner.map((item, index) => (
                            <div key={index} className="each-slide">
                                <div style={{'backgroundImage': `url(${item.acf.imagem.url})`}}>
                                    <span>{item.title.rendered}</span>
                                </div>
                            </div>
                        ))
                    }
                </Slide>

            </div>
        );
    }
};