import React, {Component} from 'react';
import axios from "axios";
import ShowSlide from "../../layout/ShowSlide";
import Loading from "../../../assets/images/loading.gif";
import Documento from "../../../assets/images/icon-documentos.png";

class Entrevistas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            loading: false,
            currentPage: 1,
            perPage: 10
        }
    }

    handleClick = (event) => {
        this.setState({
            currentPage: Number(event.target.id)
        });
        window.scrollTo(0, 750)
    }

    getDados = async () => {
        this.setState({loading: true})
        await axios.get(`${process.env.REACT_APP_API}/publicacao?per_page=100`)
            .then(response => {
                let result = response.data.filter(item => {
                    return item.acf.tipo_de_publicacao.value === "entrevistas"
                })
                if(!result) {
                    result = []
                }
                this.setState({
                    dados: result,
                    loading: false
                })
            })
    }

    componentDidMount() {
        this.getDados()
    }
    render() {
        const { dados, loading, currentPage, perPage} = this.state;

        const lastData = currentPage * perPage;
        const firstData = lastData - perPage;
        const currentData = dados.slice(firstData, lastData);

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(dados.length / perPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <li
                    key={number}
                    id={number}
                    onClick={this.handleClick}
                >
                    {number}
                </li>
            );
        });

        return (
            <>
                <ShowSlide/>
                <div className="content">
                    {loading ? (
                        <div style={{textAlign: 'center'}}>
                            <img src={Loading} alt="Carregando"/>
                        </div>
                    ) : (
                        <div className="noticia">
                            <h1>Entrevistas</h1>
                            {
                                currentData.map(item => (
                                    <div key={item.id} className="documentos">
                                        <a href={item.acf.arquivo.url} download target="_blank" rel="noopener noreferrer"><img src={Documento} alt={item.title.rendered} width='80'/></a>
                                        <div>
                                            <h2 dangerouslySetInnerHTML={{__html: item.title.rendered }} style={{ marginTop: '15px'}}></h2>
                                            <h5 dangerouslySetInnerHTML={{__html: item.acf.data_publicacao }}></h5>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    )}
                    <ul className="pagination">
                        {renderPageNumbers}
                    </ul>
                </div>
            </>
        );
    }
}

export default Entrevistas;
