import React from 'react';
import MenuMob from "./MenuResponsivo";
import Logo from '../../assets/images/logo-topo.jpg';
import '../../assets/css/index.css'

export default function MenuTopo() {
    return (
        <div>
            <div className="topo">
                <MenuMob/>
                <div className="logo">
                    <a href="/"><img src={Logo} alt="Logo AINPGP"/></a>
                </div>
                <nav>
                    <ul className="menu icon">
                        <li><a href="/">Início</a></li>
                        <li>Quem Somos &#9662;
                            <ul>
                                <li><a href="/quem-somos/historico">Histórico</a></li>
                                <li><a href="/quem-somos/diretoria">Diretoria</a></li>
                                <li><a href="/quem-somos/documentos">Estatuto e documentos</a></li>
                            </ul>
                        </li>
                        <li>Associação &#9662;
                            <ul>
                                <li><a href="/associacao/associados">Associados</a></li>
                                <li><a href="/associacao/associe">Associe-se </a></li>
                            </ul>
                        </li>
                        <li>FIPED &#9662;
                            <ul>
                                <li><a href="/fiped/historico">Histórico do evento</a></li>
                                <li><a href="/fiped/informes">Informes FIPED</a></li>
                                <li><a href="/fiped/edicoes">Todas as edições </a></li>
                            </ul>
                        </li>
                        <li>Repositório Digital &#9662;
                            <ul>
                                <li><a href="/publicacoes/anais">Anais</a></li>
                                <li><a href="/publicacoes/ebooks">E-books  </a></li>
                                <li><a href="/publicacoes/entrevistas">Entrevistas  </a></li>
                            </ul>
                        </li>
                        <li><a href="/observatorio">Observatório</a></li>
                        <li><a href="/videos">Vídeos</a></li>
                        <li><a href="/noticias">Notícias</a></li>
                        <li><a href="/contatos">Contatos</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};
