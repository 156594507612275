import React, {Component} from 'react';
import axios from "axios";
import ShowSlide from "../../layout/ShowSlide";
import Loading from "../../../assets/images/loading.gif";
import {Link} from "react-router-dom";
import moment from "moment";

class Observatorio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            loading: false,
            currentPage: 1,
            perPage: 10
        }
    }


    getDados = async () => {
        this.setState({loading: true})
        await axios.get(`${process.env.REACT_APP_API}/observatorio`)
            .then(response => {
                this.setState({
                    dados: response.data,
                    loading: false
                })
            })
    }

    componentDidMount() {
        this.getDados()
    }

    render() {
        const { dados, loading, currentPage, perPage } = this.state;
        const lastData = currentPage * perPage;
        const firstData = lastData - perPage;
        const currentData = dados.slice(firstData, lastData);

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(dados.length / perPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
              <li
                key={number}
                id={number}
                onClick={this.handleClick}
              >
                  {number}
              </li>
            );
        });

        return (
          <>
              <ShowSlide/>
              <div className="content">
                  {loading ? (
                    <div style={{textAlign: 'center'}}>
                        <img src={Loading} alt="Carregando"/>
                    </div>
                  ) : (
                    <div className="noticia">
                        <h1>Observatório </h1>
                        {
                            currentData.map((item, index) => (
                              <div key={index} className="repositorio__list">

                                  <div>
                                      <Link to={`/observatorio/${item.slug}`}>
                                          <h2 dangerouslySetInnerHTML={{__html: item.title.rendered}}></h2>
                                          <h4>Em { moment(item.date).format('D [de] MMMM, YYYY') }</h4>
                                      </Link>
                                  </div>
                              </div>
                            ))
                        }
                        <ul className="pagination">
                            {renderPageNumbers}
                        </ul>
                    </div>
                  )}
              </div>
          </>
        );
    }
}

export default Observatorio;
