import React from 'react';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import Home from "./components/content/home/Index";
import Historico from "./components/content/quem-somos/Historico";
import Erro404 from "./components/layout/Erro404";
import Listagem from "./components/content/noticias/Listagem";
import Noticia from "./components/content/noticias/Noticia";
import ScrollToTop from "./components/layout/ScrollToTop";
import Diretoria from "./components/content/quem-somos/Diretoria";
import Documentos from "./components/content/quem-somos/Documentos";
import Associados from "./components/content/associacao/Associados";
import HistoricoFiped from "./components/content/fiped/Historico";
import Informes from "./components/content/fiped/Listagem";
import InformeLink from "./components/content/fiped/InformeLink";
import Edicoes from "./components/content/fiped/Edicoes";
import Edicao from "./components/content/fiped/Edicao";
import Anais from "./components/content/publicacoes/Anais";
import Ebooks from "./components/content/publicacoes/Ebooks";
import Entrevistas from "./components/content/publicacoes/Entrevistas";
import Observatorio from "./components/content/observatorio/Observatorio";
import Item from "./components/content/observatorio/Noticia";
import ListagemVideos from "./components/content/videos/Listagem";
import Contatos from "./components/content/contatos/Contatos";
import Associe from "./components/content/associacao/Associe";
import Paypal from "./components/content/associacao/Paypal";
import Callback from "./components/content/associacao/Callback";

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Switch>
                <Route path="/" exact={true} component={Home}/>
                <Route path="/noticias/:slug" component={Noticia}/>
                <Route path="/noticias" component={Listagem}/>
                <Route path="/quem-somos/historico" exact={true} component={Historico}/>
                <Route path="/quem-somos/diretoria" exact={true} component={Diretoria}/>
                <Route path="/quem-somos/documentos" exact={true} component={Documentos}/>
                <Route path="/associacao/associados" exact={true} component={Associados}/>
                <Route path="/associacao/associe" exact={true} component={Associe}/>
                <Route path="/associacao/paypal" exact={true} component={Paypal}/>
                <Route path="/associacao/callback" exact={true} component={Callback}/>
                <Route path="/fiped/historico" exact={true} component={HistoricoFiped}/>
                <Route path="/fiped/informes" exact={true} component={Informes}/>
                <Route path="/fiped/informes/:slug" exact={true} component={InformeLink}/>
                <Route path="/fiped/edicoes" exact={true} component={Edicoes}/>
                <Route path="/fiped/edicoes/:slug" exact={true} component={Edicao}/>
                <Route path="/publicacoes/anais" exact={true} component={Anais}/>
                <Route path="/publicacoes/ebooks" exact={true} component={Ebooks}/>
                <Route path="/publicacoes/entrevistas" exact={true} component={Entrevistas}/>
                <Route path="/observatorio" exact={true} component={Observatorio}/>
                <Route path="/observatorio/:slug" component={Item}/>
                <Route path="/videos" exact={true} component={ListagemVideos}/>
                <Route path="/contatos" exact={true} component={Contatos}/>
                <Route path='*' component={Erro404}/>
            </Switch>
        </ BrowserRouter>
    );
}

export default App;
