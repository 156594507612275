import React, {Component} from 'react';
import ShowSlide from "../../layout/ShowSlide";
import Loading from "../../../assets/images/loading.gif";
import axios from "axios";

class Edicao extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: {},
            loading: false
        }
    }

    getDados = async () => {
        this.setState({ loading: true })
        const { match: { params } } = this.props;
        await axios.get(`${process.env.REACT_APP_API}/fiped?slug=${params.slug}`)
            .then(response => {
                this.setState({
                    dados: {
                        titulo: response.data[0].title.rendered,
                        conteudo: response.data[0].content.rendered,
                        data: response.data[0].acf.ano,
                        local: response.data[0].acf.localizacao,
                    },
                    loading: false
                })
            })
    }

    componentDidMount() {
        this.getDados();
    }

    render() {
        const { dados, loading } = this.state;
        return (
            <>
                <ShowSlide/>
                <div className="content">
                    {loading ? (
                        <div style={{textAlign: 'center'}}>
                            <img src={Loading} alt="Carregando"/>
                        </div>
                    ) : (
                        <div className="noticia">
                            { loading ? (
                                <div style={{ textAlign: 'center' }}>
                                    <img src={Loading} alt="Carregando"/>
                                </div>
                            ) : (
                                <>
                                    <h1>Edição <small dangerouslySetInnerHTML={{__html: dados.titulo }}></small></h1>
                                    <div className="noticia">
                                        <div>
                                            <h4 className="local">{ dados.local } - { dados.data }</h4>
                                            <h4 dangerouslySetInnerHTML={{__html: dados.conteudo }}></h4>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )
                    }
                </div>
            </>
        );
    }
}

export default Edicao;