import React, {useState} from 'react';
import ShowSlide from "../../layout/ShowSlide";
import axios from 'axios';
import SweetAlert from 'sweetalert2-react';

import useForm from "./useForm";

const Contatos = () => {

  const [show, setShow] = useState(false);

  const [{values, loading}, onChangeInput, enviaMail] = useForm();

  const enviarContato = () => {
    let {nome, email, telefone, mensagem} = values
    axios.post(`${process.env.REACT_APP_EMAIL}`, {
      nome, email, telefone, mensagem
    })
      .then(() => {
        console.log('Email enviado com sucesso!')
        setShow(true)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <>
      <ShowSlide/>
      <div className="content">
        <div className="noticia">
          <h1>Contatos <small>Fale Conosco</small></h1>
          <form action="" onSubmit={enviaMail(enviarContato)} method="post">
            <input type="text" name="nome"
                   placeholder="Nome"
                   onChange={onChangeInput} required/>
            <input type="email" name="email"
                   placeholder="E-mail"
                   onChange={onChangeInput} required/>
            <input type="tel" name="telefone"
                   placeholder="Telefone"
                   onChange={onChangeInput}/>
            <textarea name="mensagem"
                      placeholder="Mensagem"
                      onChange={onChangeInput} required/>
            <input type="submit" value={loading ? "Enviando..." : "Enviar"}/>
          </form>
          <SweetAlert
            show={show}
            title="Concluído"
            text="Formulário enviado com sucesso!"
            onConfirm={() => setShow(false)}
          />
        </div>
      </div>
    </>
  );
}

export default Contatos;
