import React, {Component} from 'react';
import ShowSlide from "../../layout/ShowSlide";
import Loading from "../../../assets/images/loading.gif";
import axios from "axios";
import _ from 'lodash';
import Usuario from "../../../assets/images/user.png";
import {Link} from "react-router-dom";

class Edicoes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            loading: false,
            currentPage: 1,
            perPage: 5
        }
    }

    handleClick = (event) => {
        this.setState({
            currentPage: Number(event.target.id)
        });
        window.scrollTo(0, 750)
    }

    getDados = async () => {
        this.setState({loading: true})
        await axios.get(`${process.env.REACT_APP_API}/fiped?per_page=100`)
            .then(response => {
                let result = response.data.filter(item => {
                    return item.acf.historico === false
                })
                let filtrado = result.filter(item => {
                    return item.acf.informes_fiped !== true
                })
                this.setState({
                    dados: _.orderBy(filtrado, ['acf.ano','title.rendered'], ['desc', 'asc']),
                    loading: false
                })
            })
    }

    componentDidMount() {
        this.getDados()
    }

    render() {
        const { dados, loading, currentPage, perPage } = this.state;

        const lastData = currentPage * perPage;
        const firstData = lastData - perPage;
        const currentData = dados.slice(firstData, lastData);

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(dados.length / perPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <li
                    key={number}
                    id={number}
                    onClick={this.handleClick}
                >
                    {number}
                </li>
            );
        });


        return (
            <>
                <ShowSlide/>
                <div className="content">
                    {loading ? (
                        <div style={{textAlign: 'center'}}>
                            <img src={Loading} alt="Carregando"/>
                        </div>
                    ) : (
                        <div className="noticia">
                            <h1>FIPED <small> Todas as Edições</small></h1>
                            {
                                currentData.map((item, index) => (
                                    <div key={index} className="fiped__list">
                                        <div>
                                            <Link to={`/fiped/edicoes/${item.slug}`}>
                                                { item.acf.imagem ? (
                                                    <img src={item.acf.imagem.url} alt={item.title.rendered} width='100'/>
                                                ) : (
                                                    <img src={Usuario} alt={item.title.rendered} width='100'/>
                                                )}
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to={`/fiped/edicoes/${item.slug}`}>
                                                <h2 dangerouslySetInnerHTML={{__html: item.title.rendered}}></h2>
                                            </Link>
                                            <h4>Ano: { item.acf.ano }</h4>
                                            <h4>Local: { item.acf.localizacao } </h4>
                                        </div>
                                    </div>
                                ))
                            }
                            <ul className="pagination">
                                {renderPageNumbers}
                            </ul>
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default Edicoes;
