import React from 'react';
import { Link } from "react-router-dom";

const FacaParte = () => {
    return (
        <div className="faca-parte">
            <h1>Faça Parte da AINPGP</h1>
            <button>
                <Link to="/associacao/associe">Associe-se agora</Link>
            </button>
            <Link to="/associacao/associe">
                <h6>Veja mais informações</h6>
            </Link>
        </div>
    );
};

export default FacaParte;