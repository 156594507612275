import React from 'react';

const Mapa = (props) => (
    <div id="maps">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.1526634165793!2d-38.560574385767175!3d-6.872304369149775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7a3f549dbf0c385%3A0xa610ce88a3eec6ee!2sUFCG%2C%20Universidade%20Federal%20de%20Campina%20Grande%20-%20Campus%20Cajazeiras-PB!5e0!3m2!1spt-BR!2sbr!4v1575369533262!5m2!1spt-BR!2sbr"
            width="100%" height="100%" title="Mapa" frameBorder="0" style={{border: '0'}} allowFullScreen></iframe>
    </div>
);

export default Mapa;