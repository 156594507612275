import React, {Component} from 'react';
import ShowSlide from "../../layout/ShowSlide";
import Loading from "../../../assets/images/loading.gif";
import axios from "axios";

class Historico extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: [],
            loading: false
        }
    }

    getDados = async () => {
        this.setState({loading: true})
        await axios.get(`${process.env.REACT_APP_API}/fiped`)
            .then(response => {
                let result = response.data.filter(item => {
                    return item.acf.historico === true
                })
                this.setState({
                    dados: result,
                    loading: false
                })
            })
    }

    componentDidMount() {
        this.getDados()
    }

    render() {
        const { dados, loading } = this.state;
        return (
            <>
                <ShowSlide/>
                <div className="content">
                    {loading ? (
                        <div style={{textAlign: 'center'}}>
                            <img src={Loading} alt="Carregando"/>
                        </div>
                    ) : (
                        <div className="noticia">
                            <h1>FIPED <small>Histórico do Evento</small></h1>
                            {
                                dados.map(item => (
                                    <span key={item.id}>

                                        <h4 dangerouslySetInnerHTML={{__html: item.content.rendered }}></h4>
                                    </span>
                                ))
                            }
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default Historico;