import React, {Component} from 'react';
import ShowSlide from "../../layout/ShowSlide";

class Paypal extends Component {

  render() {
    return (
      <>
        <ShowSlide/>
        <div className="content">
          <div className="noticia">
            <h1>Dados registrados</h1>
            <h4>Seus dados foram computados com sucesso!
              <br />Segue abaixo o número da conta para depósito. Depois, enviar o comprovante para o e-mail: <a
              href="mailto:ainpgp@gmail.com">ainpgp@gmail.com</a>.
              <br />Agradecemos!
            </h4>
            <h2>
              <small >Banco do Brasil
                <br />Agência 1109-6
                <br /> CC 45190-8
              </small>
            </h2>
          </div>
        </div>
      </>
    );
  }
}

export default Paypal;
